import React from 'react';
import { BottomNavigation, Box, Typography, Grid, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import { FaMediumM } from 'react-icons/fa';

const CustomBottomNavigation = () => {
  const [value, setValue] = React.useState(0);

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      sx={{
        borderTop: '1px solid rgba(255, 255, 255, 0.3)',
        backgroundColor: 'transparent',
        paddingLeft: 8,
        paddingRight: 8,
        '@media (min-width: 400px)': {
          flexDirection: 'column',
          height: 'auto',
          alignItems: 'center',
        },
      }}
    >
      <Box sx={{ width: '100%', textAlign: 'center', mt: 4, mb: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" fontWeight="bold" color="white" sx={{ mb: 2, textAlign: 'left' }}>Menu</Typography>
            <Link href="/about" color="inherit" underline="none">
              <Typography variant="subtitle1" color="white" sx={{ textAlign: 'left' }}>About</Typography>
            </Link>
            <Link href="/services" color="inherit" underline="none">
              <Typography variant="subtitle1" color="white" sx={{ textAlign: 'left' }}>Services</Typography>
            </Link>
            <Link href="/blog" color="inherit" underline="none">
              <Typography variant="subtitle1" color="white" sx={{ textAlign: 'left' }}>Blog</Typography>
            </Link>
            <Link href="/contact" color="inherit" underline="none">
              <Typography variant="subtitle1" color="white" sx={{ textAlign: 'left' }}>Contact</Typography>
            </Link>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" fontWeight="bold" color="white" sx={{ mb: 2, textAlign: 'left' }}>Contact</Typography>
            <Typography variant="subtitle1" color="white" sx={{ textAlign: 'left' }}>
              <EmailIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              hello@bitblossomsoftware.com
            </Typography>
            <Typography variant="subtitle1" color="white" sx={{ textAlign: 'left' }}>
              <PhoneIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              701-390-3503
            </Typography>
            <Typography variant="subtitle1" color="white" sx={{ textAlign: 'left' }}>
              <LocationOnIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Location: Fargo, ND
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" fontWeight="bold" color="white" sx={{ mb: 2, textAlign: 'left' }}>Follow Us</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
              <Link href="https://www.facebook.com/share/1D7Gs6mkDP/" target="_blank" rel="noopener">
                <FacebookIcon sx={{ color: 'white', fontSize: 40 }} />
              </Link>
              <Link href="https://www.linkedin.com/in/sydney-boschert-67074749" target="_blank" rel="noopener">
                <LinkedInIcon sx={{ color: 'white', fontSize: 40 }} />
              </Link>
              <Link href="https://medium.com/@bitblossomsoftware" target="_blank" rel="noopener">
                <FaMediumM style={{ color: 'white', fontSize: 40 }} />
              </Link>
              <Link href="https://www.instagram.com/bitblossomsoftware/" target="_blank" rel="noopener">
                <InstagramIcon sx={{ color: 'white', fontSize: 40 }} />
              </Link>
              <Link href="https://github.com/sydneyboschert" target="_blank" rel="noopener">
                <GitHubIcon sx={{ color: 'white', fontSize: 40 }} />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
            <Box
              component="img"
              src={`BitBlossom_Logo_White.PNG`}
              alt="Bit Blossom Software Logo"
              sx={{ width: '180px', height: 'auto' }}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Typography variant="body1" color="white">Built by: Bit Blossom Software</Typography>
          </Grid>
        </Grid>
      </Box>
    </BottomNavigation>
  );
};

export default CustomBottomNavigation;