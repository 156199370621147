import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { ListItemText, ButtonBase } from '@mui/material';

interface AppBarWithLogoProps {
  logoAlt: string;
  logoSrc: string;
  appName: string;
}

const ResponsiveAppBar: React.FC<AppBarWithLogoProps> = ({ logoAlt, logoSrc, appName }) => {
  // const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //const pages = ['About', 'Services', 'Blog', 'Contact'];
  const pages = ['']
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const onNavItemClick = (page: string) => {
    navigate(`/${page.toLowerCase()}`);
    // handleClose
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        {appName}
      </Typography>
      <List>
        {pages.map((page, index) => (
          <ListItemButton key={`btn_${page}_${index}`} onClick={() => onNavItemClick(page)} >
            <ListItemText key={`txt_${page}_${index}`} primary={page} />
          </ListItemButton>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar position="sticky">
      <Toolbar>

        <ButtonBase onClick={() => navigate("/")}  component="div"  >
        <Box
          component="img"
          sx={{
            height: 55,
            margin: 1,
          }}
          alt={logoAlt}
          src={logoSrc}
        />
        
{/*       
            <Typography variant="h2" component="div">
              {appName}
            </Typography> */}
           
        </ButtonBase>
        <Box sx={{ flexGrow: 1 }} />
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: 'flex' }}>
            {pages.map((page) => (
              <ButtonBase key={`typography_${page}`}  onClick={() => onNavItemClick(page)}>
                <Typography
                  key={`typography_${page}`} 
                  variant="h6"
                  component="div"
                  sx={{ margin: theme.spacing(1) }}
                >
                  {page}
                </Typography>
              </ButtonBase>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default ResponsiveAppBar;