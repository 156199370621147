import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme  } from '@mui/material/styles';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Blog from './pages/Blog';
import Contact from './pages/Contact';

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      tertiary: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      tertiary?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#5C007C' // #Royal_Violet
    },
    secondary: {
      main: '#CB77FF', //Fuschia
    },
    custom: {
      tertiary: '#004752', // Evergreen Third primary color
    },
    common: {
      white: '#EFECE5',
      black: '#000000',
    },
  },
  typography: {
    fontFamily: 'DM Sans, sans-serif',
    h1: {
      fontFamily: 'Signika, sans-serif',
      fontWeight: 700, // Bold
    },
    h2: {
      fontFamily: 'Signika, sans-serif',
      fontWeight: 400, // Regular
    },
    h3: {
      fontFamily: 'DM Sans, sans-serif',
      fontWeight: 800, // Extra bold
    },
    body1: {
      fontFamily: 'DM Sans, sans-serif',
    },
  },
});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;