import React from 'react';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';


const Services: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc="logo512.png"
                appName="Bit Blossom Software"
            />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                sx={{
                    background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    color: theme.palette.common.white,
                    textAlign: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                    Services
                </Typography>
            </Box>
            <BottomNav />
        </>
    )
};

export default Services;