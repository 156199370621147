export type Blog = {
    title: string;
    abstract: string;
    image: string;
    id: number;
};

export const Blogs: Blog[] = [
    {
        title: "Kajabi Webhook Integration into a 3rd Party App", 
        abstract: "A brief blog post on how to properly integrate Kajabi webhooks into a 3rd party app.",
        image: "kajabi-webhook-integration.png",
        id: 0
    }
]
