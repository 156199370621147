// src/Home.tsx
import React from 'react';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
// import Grow from '@mui/material/Grow';
import CodeIcon from '@mui/icons-material/Code';
import HandshakeIcon from '@mui/icons-material/Handshake';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography, Grid, ButtonBase } from '@mui/material';
import { motion } from 'framer-motion';


import { useTheme } from '@mui/material/styles';


const Home: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc={`BitBlossom_Logo_White.PNG`}
                appName="Bit Blossom Software"
            />
            <Box
                display="flex"
                flexDirection="column"
                paddingTop={8}
                paddingLeft={8}
                paddingRight={8}
                paddingBottom={0}
                sx={{
                    // background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    // background: `radial-gradient(circle at 80.2734375% 100%, ${theme.palette.primary.main} 0%, 17.5%, rgba(21, 16, 37, 0) 35%), 
                    // radial-gradient(circle at 98.46028645833333% 68.671875%, ${theme.palette.secondary.main} 0%, 20%, rgba(73, 0, 148, 0) 40%), 
                    // radial-gradient(circle at 0% 0%, rgba(73, 0, 148, 0.99) 0%, 25%, rgba(73, 0, 148, 0) 50%), 
                    //   radial-gradient(circle at 48.9013671875% 49.521484375%, ${theme.palette.primary.main} 0%, 100%, rgba(21, 16, 37, 0) 100%)`,
                    // background: `radial-gradient(circle at 80.2734375% 100%, ${theme.palette.primary.main} 0%, 17.5%, rgba(21, 16, 37, 0) 35%), 
                    //    radial-gradient(circle at 98.46028645833333% 68.671875%, ${theme.palette.secondary.main} 0%, 20%, rgba(73, 0, 148, 0) 40%), 
                    //    radial-gradient(circle at 0% 0%, ${theme.palette.custom.tertiary} 0%, 25%, rgba(73, 0, 148, 0) 50%), 
                    //   radial-gradient(circle at 48.9013671875% 49.521484375%, ${theme.palette.primary.main} 0%, 100%, rgba(21, 16, 37, 0) 100%)`,
                    background:
                        `radial-gradient(circle at 80.2734375% 100%, ${theme.palette.secondary.main} 0%, 17.5%, rgba(21, 16, 37, 0) 35%),
                       radial-gradient(circle at 98.46028645833333% 68.671875%, ${theme.palette.primary.main} 0%, 20%, rgba(73, 0, 148, 0) 40%), 
                       radial-gradient(circle at 0% 0%, ${theme.palette.primary.main}  0%, 25%, rgba(73, 0, 148, 0) 50%), Top left
                       radial-gradient(circle at 48.9013671875% 49.521484375%, #151025 0%, 100%, rgba(21, 16, 37, 0) 100%)`,//center
                    // radial-gradient(circle at 80.2734375% 100%, #151025 0%, 17.5%, rgba(21, 16, 37, 0) 35%),
                    //radial-gradient(circle at 98.46028645833333% 68.671875%, #490094 0%, 20%, rgba(73, 0, 148, 0) 40%),
                    //radial-gradient(circle at 0% 0%, rgba(73, 0, 148, 0.99) 0%, 25%, rgba(73, 0, 148, 0) 50%),
                    //radial-gradient(circle at 48.9013671875% 49.521484375%, #151025 0%, 100%, rgba(21, 16, 37, 0) 100%);
                    //--
                    //`radial-gradient(circle at 98.59375% 0%, #151025 0%, 17.604999999999997%, rgba(21, 16, 37, 0) 35%),
                    //   radial-gradient(circle at 0% 100%, #151025 0%, 17.5%, rgba(21, 16, 37, 0) 35%),
                    // radial-gradient(circle at 99.99348958333333% 95.52408854166666%, #490094 0%, 27.54%, rgba(73, 0, 148, 0) 54%),
                    // radial-gradient(circle at 48.9013671875% 49.521484375%, #151025 0%, 100%, rgba(21, 16, 37, 0) 100%)`,
                    color: theme.palette.common.white,


                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                <Box sx={{ width: '100%', mt: 25 }}>
                    <  Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
                        <Typography variant="h2" sx={{ textAlign: 'left', fontWeight: 400 }}>
                            Technology with Heart, Partnership with Purpose
                        </Typography>
                    </Slide>
                    <Slide direction="down" in={true} timeout={1500} mountOnEnter unmountOnExit>

                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                            <Typography variant="h4" sx={{ textAlign: 'left', fontWeight: 100 }}>
                                Empowering businesses with compassionate, tailored technology solutions.
                            </Typography>
                        </Box>
                    </Slide>
                    <Slide direction="down" in={true} timeout={1000} mountOnEnter unmountOnExit>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mb: 4, pr: 2 }}>
                            <ButtonBase
                                sx={{ display: 'flex', alignItems: 'center' }}
                                onClick={() => {
                                    const element = document.getElementById('services-section');
                                    if (element) {
                                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                    }
                                }}
                            >
                                <Button
                                    variant="text"
                                    endIcon={<ArrowForwardIosIcon sx={{ fontSize: 16, color: theme.palette.secondary.main }} />}
                                    sx={{ display: 'flex', alignItems: 'center', textTransform: 'none' }}
                                    onClick={() => {
                                        const element = document.getElementById('empowering-section');
                                        if (element) {
                                            element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
                                            window.scrollBy({ top: element.getBoundingClientRect().top - window.innerHeight / 4, behavior: 'smooth' });
                                        }
                                    }}
                                >
                                    <Typography variant="body1" sx={{ color: theme.palette.common.white, textAlign: 'right' }}>
                                        See What We Do
                                    </Typography>
                                </Button>

                            </ButtonBase>
                        </Box>
                    </Slide>

                </Box>

                <Box sx={{ borderTop: '0px solid', borderColor: 'rgba(255, 255, 255, 0.3)', mt: 20, mb: 4 }} id="empowering-section">
                    <Typography variant="h2" sx={{ mt: 4, mb: 4 }}>

                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Box
                                component="img"
                                src={`Hearts.png`}
                                alt="Hearts"
                                sx={{ width: '50%', height: 'auto', borderRadius: 8 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box>
                                <Typography  variant="h4" sx={{ mt: 4, mb: 4 }}>
                                    Empowering businesses with purpose
                                </Typography>
                                <Typography variant="body1">
                                    We believe in more than just technology—we’re here to create solutions
                                    that align with your vision and values.

                                    Every project is a partnership built on trust, empathy, and collaboration.

                                    Here, technology meets empathy, helping you feel supported, informed, and empowered to achieve your goals.
                                    With a focus on your success, we’re committed to helping you thrive by delivering purposeful,
                                    heart-centered tech solutions that grow with your business.

                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {/* ///servies</Box>     */}
                <Box sx={{ borderTop: '1px solid', borderColor: 'rgba(255, 255, 255, 0.3)', mt: 4, mb: 4 }}>


                    <Typography variant="h2" sx={{ mt: 4, mb: 4 }}>
                        Services
                    </Typography>

                    <Grid container spacing={4} sx={{ px: 4 }}>
                        <Grid item xs={12} md={4}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        m: 2,
                                        p: 4,
                                        borderRadius: 2,
                                        background: 'rgba(255, 255, 255, 0.1)',
                                        //backdropFilter: 'blur(10px)',
                                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                        border: '1px solid rgba(255, 255, 255, 0.3)',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h4" color='White' align='center'>
                                            Custom Software Development
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <CodeIcon sx={{ fontSize: 100, color: theme.palette.secondary.main, mt: 2 }} />

                                    </Box>

                                    <Box>
                                        <Typography variant="subtitle1" gutterBottom color='White'>
                                            We specialize in crafting custom software that perfectly aligns with your business goals and challenges.
                                            Whether you need a completely new application, enhancements to existing systems, or seamless integration with other tools,
                                            our solutions are designed to be scalable, efficient, and user-friendly.
                                        </Typography>
                                    </Box>

                                </Box>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        m: 2,
                                        p: 4,
                                        borderRadius: 2,
                                        background: 'rgba(255, 255, 255, 0.1)',
                                        //backdropFilter: 'blur(10px)',
                                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                        border: '1px solid rgba(255, 255, 255, 0.3)',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h4" color='White' align='center'>
                                            Technology<br />Consulting
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TipsAndUpdatesIcon sx={{ fontSize: 100, color: theme.palette.secondary.main, mt: 2 }} />

                                    </Box>

                                    <Box>
                                        <Typography variant="subtitle1" gutterBottom color='White'>
                                            We provide strategic advice and hands-on support to ensure your technology choices
                                            align with your business objectives and enhance operational efficiency and helping you navigate the ever-changing tech landscape.
                                        </Typography>
                                    </Box>
                                </Box>
                            </motion.div>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <motion.div whileHover={{ scale: 1.05 }}>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                        m: 2,
                                        p: 4,
                                        borderRadius: 2,
                                        background: 'rgba(255, 255, 255, 0.1)',
                                        //backdropFilter: 'blur(10px)',
                                        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                        border: '1px solid rgba(255, 255, 255, 0.3)',
                                        transition: 'transform 0.3s ease-in-out',
                                    }}
                                >
                                    <Box>
                                        <Typography variant="h4" color="theme.palette.common.white" align='center'>
                                            Reliable Support & Maintenance
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <HandshakeIcon sx={{ fontSize: 100, color: theme.palette.secondary.main, mt: 2 }} />
                                    </Box>
                                    <Box>
                                        <Typography variant="subtitle1" gutterBottom color="theme.palette.common.white">
                                            We don’t just deliver software and walk away; we’re committed to providing ongoing support and maintenance to
                                            ensure your systems continue to operate at their best. From regular updates to troubleshooting and scaling,
                                            we’re here to support your long-term success.
                                        </Typography>
                                    </Box>

                                </Box>
                            </motion.div>
                        </Grid>
                    </Grid>

                </Box>
                {/* Services */}


                <Box sx={{ borderTop: '1px solid', borderColor: 'rgba(255, 255, 255, 0.3)', mt: 4, mb: 4 }}>
                    <Typography variant="h2" sx={{ mt: 4, mb: 4 }}>
                        Love Notes
                    </Typography>

                    <Typography variant="h5" sx={{ mt: 4, mb: 4 }}>
                        Client Reviews Coming Soon!
                    </Typography>
                </Box>


                <Grid item xs={12} md={4}>
                    <motion.div whileHover={{ scale: 1.00 }}>
                        <Box
                            display="flex"
                            flexDirection={{ xs: 'column', md: 'row' }}
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                                mt: 4,
                                mb: 4,
                                p: 4,
                                borderRadius: 2,
                                background: 'rgba(255, 255, 255, 0.1)',
                                boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
                                border: '1px solid rgba(255, 255, 255, 0.3)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                        >
                            <Box sx={{ width: { xs: '100%', md: '60%' }, mt: 4, mb: 4 }}>
                                <Typography variant="h3" color="theme.palette.common.white" align='left'>
                                    Ready to Transform Your Business
                                </Typography>
                                <Typography variant="h5" gutterBottom color="theme.palette.common.white">
                                    With Heart-Centered Software Solutions
                                </Typography>
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: '20%' }, display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' }, mt: { xs: 2, md: 0 } }}>
                                <ArrowForwardIosIcon sx={{ fontSize: 100, color: theme.palette.secondary.main }} />
                            </Box>
                            <Box sx={{ width: { xs: '100%', md: '30%' }, display: 'flex', justifyContent: 'center', mt: { xs: 2, md: 0 } }}>
                                <Typography variant="h5" gutterBottom color="theme.palette.common.white">
                                    If so, your journey starts here
                                </Typography>
                            </Box>
                        </Box>
                    </motion.div>
                </Grid>
                <BottomNav />
            </Box>


        </>
    );
};

export default Home;