import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';

// Define the props type
interface BlogItemProps {
  title: string;
  picture: string;
  abstract: string;
}

// Create the BlogItem component
const BlogItem: React.FC<BlogItemProps> = ({ title, picture, abstract }) => {
  return (
    <Card sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', mr: 2 }}>
        <Typography variant="h6">{title}</Typography>
        <CardMedia
          component="img"
          image={picture}
          alt={title}
          sx={{ width: 100, height: 100, objectFit: 'cover' }}
        />
      </Box>
      <CardContent>
        <Typography variant="body2">{abstract}</Typography>
      </CardContent>
    </Card>
  );
};

export default BlogItem;