import React from 'react';
import AppBarWithLogo from '../components/AppBarWithLogo';
import BottomNav from '../components/BottomNav';
import { Box } from '@mui/material';
import { Blogs } from '../data/Blogs';
import BlogItem from '../components/BlogItem';
import { useTheme } from '@mui/material/styles';


const Blog: React.FC = () => {
    const theme = useTheme();

    return (
        <>
            <AppBarWithLogo
                logoAlt="Bit Blossom Software Logo"
                logoSrc="logo512.png"
                appName="Bit Blossom Software"
            />
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100vh"
                sx={{
                    backgroundColor: theme.palette.common.white,
                    textAlign: 'center',
                    position: 'relative',
                    overflow: 'hidden',
                }}
            >
                {Blogs.map((blog) => (
                    <BlogItem key={blog.id} title={blog.title} picture={blog.image} abstract={blog.abstract} />
                ))}
            </Box>
            <Box
                width="100%"
                height="1px"
                bgcolor={theme.palette.divider}
                my={2}
            />
            <BottomNav />
        </>
    )
};

export default Blog;